import React from "react";

interface Props {
  isOpen?: boolean;
  width?: string | number;
  height?: string | number;
  className?: string;
  onClick?: () => void;
}

const MenuButton = ({
  isOpen = false,
  width = 24,
  height = 24,
  className,
  onClick,
  ...props
}: Props) => {
  return (
    <div className={`${className}`}>
      <a onClick={onClick}>
        <img
          src="/icon-menu.svg"
          alt="menu"
          style={{
            transform: "scale(0.8)",
          }}
        />
      </a>
    </div>
  );
};

export { MenuButton };
