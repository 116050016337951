import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_FeaturedWork } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import Content from "components/Content/Content";
import Image from "next/image";
import { FeaturedImage } from "components/FeaturedImage";
import Link from "next/link";
interface FeaturedWorkProps extends Page_Flexiblecontent_Blocks_FeaturedWork {
  className?: string;
  servicesList?: {
    id: string;
    title: string;
    link: string;
    slug: string;
  }[];
}

const FeaturedWork = ({
  className,
  projectName,
  projectDescription,
  servicesList,
  mediaType,
  image,
  video,
  projectImages,
}: FeaturedWorkProps) => {
  let innerWrapperClass = clsx(
    `items-center relative z-10`,
    image
      ? `max-w-[1280px] mx-auto flex flex-col md:flex-row relative z-10`
      : `max-w-[1280px] mx-auto flex flex-col relative z-10`
  );

  let innerItemClass = clsx(
    `max-w-[1110px] mx-auto flex flex-col lg:flex-row items-start gap-4 w-full`
  );

  let wrapperClass = clsx("py-14 px-10 xl:px-0 relative h-auto flex flex-col");

  let textClass = clsx(
    `md:px-10 xl:px-0 mb-10 xl:mb-0 text-center lg:text-left relative flex-1`
  );
  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div className={innerItemClass}>
        <div className={textClass}>
          <h2 className="leading-12 relative z-10 mb-6 font-new-hero text-3xl font-normal md:text-5xl">
            <Content content={`Featured Work`} />
          </h2>
          <h3 className={`mb-10 text-xl text-secondary`}>{projectName}</h3>
          <div
            className={`relative z-10 mb-7 text-lg md:mb-5 md:text-xl lg:max-w-sm`}
          >
            <Content content={projectDescription} className={`text-lg`} />
          </div>

          {servicesList && (
            <ul
              className={`grid max-w-full grid-cols-1 gap-2 md:grid-cols-2 lg:max-w-md`}
            >
              {servicesList.map((service, index) => (
                <li key={index}>
                  <Link href={`/services/` + service?.slug || ""} className="text-secondary">
                    {service?.title} {`>`}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div
          className={`relative max-w-full p-2 md:p-3 lg:max-w-lg xl:max-w-2xl`}
        >
          <Image
            src="/ipad-frame.png"
            fill={true}
            alt="ipad"
            className={`pointer-events-none z-10`}
          />
          {mediaType ? (
            <FeaturedImage
              image={image}
              className="h-full w-full object-cover"
            />
          ) : (
            <video
              autoPlay
              muted
              loop
              playsInline
              className="h-full w-full object-cover"
            >
              <source src={video?.mediaItemUrl || ""} type="video/mp4" />
            </video>
          )}
        </div>
      </div>
      <div className={`mx-auto mt-5 max-w-[1110px] xl:mt-14`}>
        {projectImages && (
          <div
            className={`grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3`}
          >
            {projectImages.map((image, index) => (
              <div key={index} className={`relative`}>
                <FeaturedImage
                  image={image}
                  className="h-full w-full object-cover"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default FeaturedWork;

export const FEATURED_WORK_FRAGMENT = gql`
  fragment FeaturedWorkFragment on Page_Flexiblecontent_Blocks_FeaturedWork {
    __typename
    fieldGroupName
    projectName
    projectDescription
    mediaType
    servicesList: services {
      ... on Page {
        id
        title
        link
        slug
      }
    }
    image {
      ...MediaItemFragment
    }
    video {
      ...MediaItemFragment
    }
    projectImages {
      ...MediaItemFragment
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
