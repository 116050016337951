import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_TextImage } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { Content } from "components/Content";
import { Button } from "components/Button";
import { FeaturedImage } from "components/FeaturedImage";
import RatingCarousel from "./Fragments/RatingCarousel";
import Image from "next/image";
interface TextImageProps extends Page_Flexiblecontent_Blocks_TextImage {
  className?: string;
}
interface ObjType {
  [key: string]: string;
}

const TextImage = ({
  className,
  hasImage,
  image,
  imageAlign,
  imageLayout,
  text,
  textWidth,
  title,
  noWrapTitle,
  hasTrademarkImage,
  trademarkImage,
  trademarkPosition,
  hasRatingCarousel,
  ratingCarousel,
  cta,
  hasFloatingImage,
  isFloatingImageVideo,
  floatingImage,
  dontPullToEdge,
}: TextImageProps) => {
  let wrapperClass = clsx(
    `py-10 md:py-20 lg:pt-20 relative h-auto flex items-center overflow-hidden lg:overflow-visible`,
  );

  let innerWrapperClass = clsx(
    `items-center relative z-10`,
    image
      ? `max-w-[1280px] mx-auto flex flex-col md:flex-row relative z-10`
      : `max-w-[1280px] mx-auto flex flex-col relative z-10`
  );

  let innerItemClass = clsx(
    `flex flex-col items-center lg:flex-row md:items-start gap-4 w-full`
  );

  let textClass = clsx(
    `px-10 xl:px-0 mb-10 xl:mb-0 text-center lg:text-left`,
    imageAlign === "left" ? "relative flex-1 md:order-2" : "relative flex-1"
  );
  let imageRatingWrapperClass = clsx(
    "w-full xl:flex-initial max-w-full lg:max-w-[65%] xl:max-w-[800px] relative"
  );
  let imageWrapperClass = clsx(
    "xl:flex-initial max-w-full relative",
    imageAlign === "left"
      ? `lg:pr-10 ${dontPullToEdge ? "" : "ml-0 md:-ml-20"}`
      : `lg:pl-10 ${dontPullToEdge ? "" : "mr-0 md:-mr-20"}`,
    imageLayout === "secondary"
      ? "-mt-10 -mb-10 absolute bottom-0 -left-32 opacity-50 z-0 lg:relative lg:bottom-auto lg:left-auto lg:opacity-100"
      : "",
    hasImage ? "" : "mb-40 border-t-[#707070] border-t-[1px] pt-10"
  );
  let trademarkPositionClass = clsx(
    "hidden mt-80 lg:block pointer-events-none",
    trademarkPosition === "top"
      ? "-top-28"
      : "top-full md:right-0 xl:right-initial",
    hasImage
      ? // ? "md:mt-[30rem] lg:mt-0"
        "md:mt-[30rem] lg:-mt-40 lg:max-w-[400px] lgx:max-w-max"
      : "lg:-mt-4 left-initial -left-12 lg:-left-4 xl:-left-46"
  );

  const textLength: ObjType = {
    sm: "lg:max-w-sm",
    smd: "lg:max-w-[26rem]",
    md: "lg:max-w-md",
    lg: "lg:max-w-lg",
    xl: "lg:max-w-xl",
    "2xl": "lg:max-w-2xl",
  };

  return (
    <section
      className={`${className ? className : ``} ${wrapperClass} ${
        hasFloatingImage && !hasTrademarkImage && !hasRatingCarousel
          ? `lg:pb-20`
          : `lg:pb-0`
      }`}
    >
      <div className={innerWrapperClass}>
        <div className={innerItemClass}>
          <div className={textClass}>
            <h2
              className={`leading-12 relative z-10 mb-6 font-new-hero text-3xl font-normal md:text-5xl ${
                noWrapTitle ? "whitespace-nowrap" : ""
              }`}
            >
              <Content content={title} />
            </h2>
            <div
              className={`relative z-10 mb-7 text-lg md:mb-5 md:text-xl ${
                textWidth ? textLength[textWidth] : "lg:max-w-sm"
              }`}
            >
              <Content content={text} />
            </div>
            {cta ? (
              <Button type="primary" href={cta?.url || ""} className="relative">
                {cta.title}
                <div className="absolute -right-10 top-0 flex h-full w-10 items-center justify-center">
                  &gt;
                </div>
              </Button>
            ) : null}
            {hasTrademarkImage && trademarkImage ? (
              <div
                className={`absolute ${trademarkPositionClass} w-[450px] md:w-[600px]`}
              >
                <Image
                  src={trademarkImage?.sourceUrl || ""}
                  alt={trademarkImage?.altText || ""}
                  width="100"
                  height="100"
                  className="w-full"
                />
              </div>
            ) : null}
          </div>
          {(hasImage || hasRatingCarousel) && (
            <div className={imageRatingWrapperClass}>
              <div className={imageWrapperClass}>
                {image ? (
                  <FeaturedImage
                    image={image}
                    className="h-full w-full object-cover"
                  />
                ) : null}
                {hasFloatingImage && floatingImage ? (
                  <>
                    {isFloatingImageVideo ? (
                      <div className="absolute top-20 w-[200px] md:top-60 lg:-bottom-20 lg:-left-10 lg:top-auto">
                        <Image
                          src="/iphone-shell.png"
                          alt="iPhone"
                          width="100"
                          height="100"
                          className="relative z-10 h-auto w-[200px]"
                        />
                        <div className="absolute left-0 top-0 z-0 flex h-full w-full items-center justify-center px-5 pb-[22px] pt-2">
                          <video
                            autoPlay
                            loop
                            muted
                            playsInline
                            className="h-full w-full object-cover"
                          >
                            <source
                              src="/j3-iphone-video.mp4"
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      </div>
                    ) : (
                      <div className="absolute -bottom-10 w-[95px] sm:w-[150px] md:-left-10 md:top-60 lg:-bottom-20 lg:top-auto lg:w-[200px]">
                        <FeaturedImage
                          image={floatingImage}
                          className="h-full w-full object-cover"
                        />
                      </div>
                    )}
                  </>
                ) : null}
              </div>
              {hasRatingCarousel && (
                <RatingCarousel
                  ratings={ratingCarousel || []}
                  hasImage={hasImage ?? false}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TextImage;

export const TEXT_IMAGE_FRAGMENT = gql`
  fragment TextImageFragment on Page_Flexiblecontent_Blocks_TextImage {
    __typename
    hasImage
    cta {
      title
      url
      target
    }
    hasTrademarkImage
    imageLayout
    imageAlign
    text
    textWidth
    title
    trademarkPosition
    trademarkImage {
      ...MediaItemFragment
    }
    hasRatingCarousel
    ratingCarousel {
      numberOfStars
      rating
    }
    image {
      ...MediaItemFragment
    }
    hasFloatingImage
    floatingImage {
      ...MediaItemFragment
    }
    dontPullToEdge
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
