import dynamic from "next/dynamic";
// Hero: (Non dynamic import) Above the fold content
import { Hero } from "components";
// Dynamic Imports: Below the fold content
import { FORM_BLOCK_FRAGMENT } from "components/Form/Form";
const Form = dynamic(() => import("components/Form/Form"), {
  ssr: true,
});
import { TEXT_IMAGE_FRAGMENT } from "components/TextImage/TextImage";
const TextImage = dynamic(() => import("components/TextImage/TextImage"), {
  ssr: true,
});
import { IPAD_SLIDER_FRAGMENT } from "components/IpadSlider/IpadSlider";
const IpadSlider = dynamic(() => import("components/IpadSlider/IpadSlider"), {
  ssr: true,
});
import { TIMELINE_FRAGMENT } from "components/Timeline/Timeline";
const Timeline = dynamic(() => import("components/Timeline/Timeline"), {
  ssr: true,
});
import { LOGO_GRID_FRAGMENT } from "components/LogoGrid/LogoGrid";
const LogoGrid = dynamic(() => import("components/LogoGrid/LogoGrid"), {
  ssr: true,
});
import { SERVICES_GRID_FRAGMENT } from "components/ServicesGrid/ServicesGrid";
const ServicesGrid = dynamic(
  () => import("components/ServicesGrid/ServicesGrid"),
  {
    ssr: true,
  }
);
import { CONTRAST_BLOCK_FRAGMENT } from "components/ContrastBlock/ContrastBlock";
const ContrastBlock = dynamic(
  () => import("components/ContrastBlock/ContrastBlock"),
  {
    ssr: true,
  }
);
import { FEATURED_WORK_FRAGMENT } from "components/FeaturedWork/FeaturedWork";
const FeaturedWork = dynamic(
  () => import("components/FeaturedWork/FeaturedWork"),
  {
    ssr: true,
  }
);

import {
  Page_Flexiblecontent_Blocks,
  Page_Flexiblecontent_Blocks_Hero,
  Page_Flexiblecontent_Blocks_TextImage,
  Page_Flexiblecontent_Blocks_Form,
  Page_Flexiblecontent_Blocks_IpadSlider,
  Page_Flexiblecontent_Blocks_Timeline,
  Page_Flexiblecontent_Blocks_LogoGrid,
  Page_Flexiblecontent_Blocks_ServicesGrid,
  Page_Flexiblecontent_Blocks_ContrastBlock,
  Page_Flexiblecontent_Blocks_FeaturedWork,
} from "graphql";
import { gql } from "@apollo/client";

interface BlocksProps {
  blocks: Page_Flexiblecontent_Blocks[];
}

interface BlockProps {
  block: Page_Flexiblecontent_Blocks;
}

const prefix = "Page_Flexiblecontent_Blocks_";

const Block = ({ block }: BlockProps) => {
  const { __typename } = block;

  let name = __typename && __typename.replace(prefix, "");

  switch (name) {
    // Hero
    case "Hero": {
      return <Hero {...(block as Page_Flexiblecontent_Blocks_Hero)} />;
    }
    // Form
    case "Form": {
      return <Form {...(block as Page_Flexiblecontent_Blocks_Form)} />;
    }
    // Text Image
    case "TextImage": {
      return (
        <TextImage {...(block as Page_Flexiblecontent_Blocks_TextImage)} />
      );
    }
    // Ipad Slider
    case "IpadSlider": {
      return (
        <IpadSlider {...(block as Page_Flexiblecontent_Blocks_IpadSlider)} />
      );
    }
    // Timeline
    case "Timeline": {
      return <Timeline {...(block as Page_Flexiblecontent_Blocks_Timeline)} />;
    }
    // Logo Grid
    case "LogoGrid": {
      return <LogoGrid {...(block as Page_Flexiblecontent_Blocks_LogoGrid)} />;
    }
    // Contrast Block
    case "ContrastBlock": {
      return (
        <ContrastBlock
          {...(block as Page_Flexiblecontent_Blocks_ContrastBlock)}
        />
      );
    }
    // Services Grid
    case "ServicesGrid": {
      return (
        <ServicesGrid
          {...(block as Page_Flexiblecontent_Blocks_ServicesGrid)}
        />
      );
    }
    // Featured Work
    case "FeaturedWork": {
      return (
        <FeaturedWork
          {...(block as Page_Flexiblecontent_Blocks_FeaturedWork)}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const Blocks = ({ blocks = [] }: BlocksProps): JSX.Element => {
  return (
    <>
      {blocks &&
        blocks.map((block, index) => (
          <Block block={block} key={`block-${index}`} />
        ))}
    </>
  );
};

export default Blocks;

Blocks.fragments = {
  entry: gql`
    fragment BlocksFragment on Page_Flexiblecontent {
      blocks {
        ... on Page_Flexiblecontent_Blocks_Hero {
          ...HeroFragment
        }
        ... on Page_Flexiblecontent_Blocks_Form {
          ...FormBlockFragment
        }
        ... on Page_Flexiblecontent_Blocks_TextImage {
          ...TextImageFragment
        }
        ... on Page_Flexiblecontent_Blocks_IpadSlider {
          ...IpadSliderFragment
        }
        ... on Page_Flexiblecontent_Blocks_Timeline {
          ...TimelineFragment
        }
        ... on Page_Flexiblecontent_Blocks_LogoGrid {
          ...LogoGridFragment
        }
        ... on Page_Flexiblecontent_Blocks_ServicesGrid {
          ...ServicesGridFragment
        }
        ... on Page_Flexiblecontent_Blocks_ContrastBlock {
          ...ContrastBlockFragment
        }
        ... on Page_Flexiblecontent_Blocks_FeaturedWork {
          ...FeaturedWorkFragment
        }
      }
    }
    ${Hero.fragments.entry}
    ${TEXT_IMAGE_FRAGMENT}
    ${IPAD_SLIDER_FRAGMENT}
    ${TIMELINE_FRAGMENT}
    ${LOGO_GRID_FRAGMENT}
    ${SERVICES_GRID_FRAGMENT}
    ${CONTRAST_BLOCK_FRAGMENT}
    ${FEATURED_WORK_FRAGMENT}
    ${FORM_BLOCK_FRAGMENT}
  `,
};
