import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_LogoGrid } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import Content from "components/Content/Content";
import Image from "next/image";

interface LogoGridProps extends Page_Flexiblecontent_Blocks_LogoGrid {
  className?: string;
}

const LogoGrid = ({ className, title, logos }: LogoGridProps) => {
  let wrapperClass = clsx("py-10 relative h-auto flex items-center");

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div className="container relative z-10 mx-auto flex flex-col">
        <div className="flex w-full items-start gap-4">
          <div className="flex-1">
            <h2 className="leading-12 mb-0 text-center font-new-hero text-3xl font-normal md:text-5xl">
              <Content content={title} />
            </h2>
          </div>
        </div>
        <div className="relative mx-auto flex w-full max-w-[1200px] flex-col items-center justify-center md:flex-row md:flex-wrap">
          {logos &&
            logos.map((item, index) => (
              <div className="basis-1/3 px-24 py-4" key={index}>
                <Image
                  src={item?.logo?.sourceUrl || ""}
                  alt={item?.logo?.altText || ""}
                  width="200"
                  height="200"
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default LogoGrid;

export const LOGO_GRID_FRAGMENT = gql`
  fragment LogoGridFragment on Page_Flexiblecontent_Blocks_LogoGrid {
    __typename
    title
    logos {
      logo {
        ...MediaItemFragment
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
