import { AnimatePresence, motion } from "framer-motion";
import { Button } from "components/Button";
import clsx from "clsx";
import { useState } from "react";
import Image from "next/image";
import Link from "next/link";
interface CarouselProps {
  title: string;
  variant: string;
  services: Array<any>;
}

const Carousel = ({ title, variant, services }: CarouselProps) => {
  const [currentCarouselPage, setCurrentCarouselPage] = useState(0);
  const slidesPerView = 1;
  const totalSlides = services?.length ?? 0;

  const carouselContainer = clsx(
    `container relative mx-auto flex pr-0 md:pb-20 lg:pb-0 lg:pr-5`,
    variant === "servicesList" ? `flex-col md:flex-row` : "flex-col-reverse"
  );
  const carouselWrapper = `grid grid-cols-1 relative`;
  const carouselNavigation = clsx(
    variant === "servicesList"
      ? `md:absolute md:right-6 md:top-full md:mt-0 lg:-right-36 lg:top-10`
      : "mb-8 flex justify-center items-center"
  );
  const arrowsContainer = clsx(
    `relative flex w-fit rounded-full border border-solid border-primary px-4 py-2 self-start mt-4 md:mt-0`,
    variant === "servicesList" ? `mx-auto` : "ml-10"
  );

  const handleNext = () => {
    if (
      services?.length &&
      currentCarouselPage < services.length - slidesPerView
    ) {
      setCurrentCarouselPage(currentCarouselPage + slidesPerView);
    } else {
      setCurrentCarouselPage(0);
    }
  };

  const handlePrev = () => {
    if (currentCarouselPage > 0) {
      setCurrentCarouselPage(currentCarouselPage - slidesPerView);
    } else {
      setCurrentCarouselPage((services?.length ?? 0) - slidesPerView);
    }
  };

  return (
    <div className={carouselContainer}>
      <div
        className={`${carouselWrapper} relative h-[250px] w-full md:h-[505px] md:w-[675px] lg:h-[420px] lgx:h-[600px] lg:w-[550px] lgx:w-[800px]`}
      >
        <Image
          src="/ipad-frame.png"
          fill={true}
          alt="ipad"
          className={`pointer-events-none z-10`}
        />
        <AnimatePresence initial={false} mode="popLayout">
          {services?.map((service, index) => {
            let active =
              index >= currentCarouselPage &&
              index < currentCarouselPage + slidesPerView;
            return (
              <motion.div
                key={index}
                initial={{
                  opacity: 0,
                  scale: 0.9,
                }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  delay: 0.5,
                  layout: {
                    type: "spring",
                    bounce: 0.5,
                    duration: 0.5,
                  },
                }}
                className={`${active ? "relative flex" : "hidden"}`}
              >
                <div className="pointer-events-none absolute bottom-2 left-2 top-0 z-10 w-[97%] bg-gradient-to-t from-[#020202] to-transparent opacity-50 md:left-0 md:w-full lg:left-4 lg:w-[95%]" />
                {service?.slideType === "image" ? (
                  <>
                    {service?.cta ? (
                      <Link href={service?.cta?.url || ""}>
                        <Image
                          src={service?.image?.sourceUrl || ""}
                          alt={service?.image?.altText || ""}
                          fill={true}
                          className="h-full w-full object-cover p-2 md:p-4"
                        />
                      </Link>
                    ) : (
                      <Image
                        src={service?.image?.sourceUrl || ""}
                        alt={service?.image?.altText || ""}
                        fill={true}
                        className="h-full w-full object-cover p-2 md:p-4"
                      />
                    )}
                  </>
                ) : (
                  <video
                    className="h-full w-full object-cover p-2 md:p-4"
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source
                      src={service?.video?.mediaItemUrl || ""}
                      type="video/mp4"
                    />
                  </video>
                )}
                {(service?.captionTitle || service?.captionText) && (
                  <div
                    className={`hidden max-w-lg md:absolute md:-bottom-44 md:left-0 md:block lg:-right-[28rem] lg:bottom-10 lg:left-auto lg:max-w-sm`}
                  >
                    <h3 className="mb-4 font-new-hero text-lg font-normal uppercase">
                      {service?.cta ? (
                        <Link
                          href={service?.cta?.url || ""}
                          className="text-secondary"
                        >
                          {service?.captionTitle || ""}
                        </Link>
                      ) : (
                        service?.captionTitle || ""
                      )}
                    </h3>
                    <p className="mb-4 border-l-1 border-l-secondary pl-8 text-lg">
                      {service?.captionText || ""}
                    </p>
                  </div>
                )}
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
      <div className={carouselNavigation}>
        {variant === "featuredWork" && (
          <h2 className="font-new-hero text-lg font-normal uppercase">
            {title}
          </h2>
        )}
        {totalSlides > 1 && (
          <div className={arrowsContainer}>
            <motion.button
              onClick={handlePrev}
              className={`relative -mt-1 mr-10`}
              aria-label="Previous Slide"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <p className="text-3xl text-secondary">&lt;</p>
            </motion.button>
            <div
              className={`absolute left-1/2 top-1/2 z-10 block h-[30px] w-[1px] -translate-x-1/2 -translate-y-1/2 transform bg-primary`}
            />
            <motion.button
              onClick={handleNext}
              className={`relative -mt-1`}
              aria-label="Next Slide"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <p className="text-3xl text-secondary">&gt;</p>
            </motion.button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Carousel;
