import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_ContrastBlock } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import Balancer from "react-wrap-balancer";
import Content from "components/Content/Content";

interface ContrastBlockProps extends Page_Flexiblecontent_Blocks_ContrastBlock {
  className?: string;
}

const ContrastBlock = ({
  className,
  title,
  backgroundColor,
  text,
}: ContrastBlockProps) => {
  let wrapperClass = clsx(
    "py-10 relative h-auto flex items-center mb-20",
    `bg-${backgroundColor}`,
    className ? className : ``
  );

  return (
    <section className={wrapperClass}>
      <div className="container relative z-10 mx-auto flex flex-col">
        <div className="flex w-full items-start gap-4">
          <div className="flex-1">
            <h2 className="mx-auto mb-7 max-w-3xl text-center font-new-hero text-5xl font-normal leading-snug text-white">
              <Balancer>{title}</Balancer>
            </h2>
            <div className="mx-auto flex text-center font-new-hero text-3xl text-white">
              <Content content={text || ""} className="mx-auto text-center" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContrastBlock;

export const CONTRAST_BLOCK_FRAGMENT = gql`
  fragment ContrastBlockFragment on Page_Flexiblecontent_Blocks_ContrastBlock {
    __typename
    backgroundColor
    title
    text
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
