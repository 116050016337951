import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_ServicesGrid } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import Balancer from "react-wrap-balancer";
import Image from "next/image";
import Link from "next/link";

interface ServicesGridProps extends Page_Flexiblecontent_Blocks_ServicesGrid {
  className?: string;
}

const ServicesGrid = ({ className, title, services }: ServicesGridProps) => {
  let wrapperClass = clsx("py-10 relative h-auto flex items-center mb-20");

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div className="container relative z-10 mx-auto flex flex-col">
        <div className="flex w-full items-start gap-4">
          <div className="flex-1">
            <h2 className="mx-auto mb-10 max-w-3xl text-center font-new-hero text-3xl font-normal md:text-5xl">
              <Balancer className={`leading-snug`}>{title}</Balancer>
            </h2>
          </div>
        </div>
        <div className="relative mx-auto flex w-full max-w-[900px] flex-col flex-wrap items-center justify-center md:flex-row">
          {services &&
            services.map((item, index) => (
              <div
                className="mx-0 mb-10 w-full flex-1 px-6 md:mx-6 md:mb-0 md:px-0"
                key={index}
              >
                <div className="relative h-80 w-full">
                  <Link
                    href={item?.link?.url || ""}
                    target={item?.link?.target || ""}
                  >
                    <Image
                      src={item?.image?.sourceUrl || ""}
                      alt={item?.image?.altText || ""}
                      fill={true}
                      className={`object-cover`}
                    />
                  </Link>
                </div>
                <h3 className={`mt-6 text-center text-2xl md:mt-3`}>
                  {item?.text}
                </h3>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesGrid;

export const SERVICES_GRID_FRAGMENT = gql`
  fragment ServicesGridFragment on Page_Flexiblecontent_Blocks_ServicesGrid {
    __typename
    title
    services {
      image {
        ...MediaItemFragment
      }
      text
      link {
        title
        url
        target
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
