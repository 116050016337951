import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Image from "next/image";
interface CarouselProps {
  ratings: Array<any>;
  hasImage: boolean;
}

const RatingCarousel = ({ ratings, hasImage }: CarouselProps) => {
  const [currentCarouselPage, setCurrentCarouselPage] = useState(0);
  const slidesPerView = 1;

  const carouselWrapper = `grid grid-cols-${slidesPerView} gap-6 relative w-[70%] md:w-full mx-auto md:mx-0 lg:pb-10 md:px-20 md:pb-0`;
  const marginTop = hasImage ? "mt-40 lg:mt-10" : "mt-0";

  const handleNext = () => {
    if (
      ratings?.length &&
      currentCarouselPage < ratings.length - slidesPerView
    ) {
      setCurrentCarouselPage(currentCarouselPage + slidesPerView);
    } else {
      setCurrentCarouselPage(0);
    }
  };

  const handlePrev = () => {
    if (currentCarouselPage > 0) {
      setCurrentCarouselPage(currentCarouselPage - slidesPerView);
    } else {
      setCurrentCarouselPage((ratings?.length ?? 0) - slidesPerView);
    }
  };

  return (
    <div
      className={`container relative mx-auto flex flex-col md:mt-28 md:flex-row md:px-4 ${marginTop}`}
    >
      <motion.button
        onClick={handlePrev}
        className={`absolute left-5 top-3/4 z-10 -mt-4 -translate-y-1/2 transform md:left-10 md:top-1/2`}
        aria-label="Previous Slide"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <p className="text-3xl">&lt;</p>
      </motion.button>
      <div className={carouselWrapper}>
        <AnimatePresence initial={false} mode="popLayout">
          {ratings?.map((rating, index) => {
            let active =
              index >= currentCarouselPage &&
              index < currentCarouselPage + slidesPerView;
            return (
              <motion.div
                key={index}
                initial={{
                  opacity: 0,
                  scale: 0.9,
                }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  delay: 0.5,
                  layout: {
                    type: "spring",
                    bounce: 0.5,
                    duration: 0.5,
                  },
                }}
                className={`mt-16 md:mt-0 md:pb-0 ${
                  active ? "flex " : "hidden"
                }`}
              >
                <div>
                  <div className="flex flex-row items-center justify-center gap-2 text-center">
                    {[...Array(rating?.numberOfStars)].map((e, i) => (
                      <Image
                        key={i}
                        src="/star.svg"
                        width={36}
                        height={34}
                        className="w-9"
                        alt="Star Icon"
                      />
                    ))}
                    {[...Array(5 - rating?.numberOfStars)].map((e, i) => (
                      <Image
                        key={i}
                        src="/star-empty.svg"
                        width={36}
                        height={34}
                        className="w-9"
                        alt="Star Icon"
                      />
                    ))}
                  </div>
                  <p className="mt-5 text-center text-xl md:text-2xl">
                    {rating?.rating}
                  </p>
                </div>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
      <motion.button
        onClick={handleNext}
        className={`absolute right-5 top-3/4 z-10 -mt-4 -translate-y-1/2 transform md:right-10 md:top-1/2`}
        aria-label="Next Slide"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <p className="text-3xl">&gt;</p>
      </motion.button>
    </div>
  );
};

export default RatingCarousel;
