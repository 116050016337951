import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_Hero } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import Image from "next/image";
import { Content } from "components/Content";
import ReactTextTransition, { presets } from "react-text-transition";
import { useEffect, useState } from "react";

interface HeroProps extends Page_Flexiblecontent_Blocks_Hero {
  className?: string;
}
interface ObjType {
  [key: string]: string;
}

const Hero = ({
  className,
  title,
  isH1,
  ctas,
  video,
  image,
  content,
  titleLength,
  isHome,
}: HeroProps) => {
  let wrapperClass = clsx(
    "py-24 relative h-full md:h-screen flex items-center z-30"
  );

  let innerItemClass = clsx(`absolute top-0 left-0 w-full h-full`);

  let videoWraperClass = clsx(`absolute w-full h-full top-0 left-0 z-0`);

  const [index, setIndex] = useState(0);
  const TITLES = ["life on automatic", "needs", "personal touch", "budget"];

  useEffect(() => {
    if (!isHome) return;
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000);
    return () => clearTimeout(intervalId);
  }, [isHome]);
  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div className={`z-20 flex w-full flex-col items-center`}>
        {isHome ? (
          <h1
            className={`hero-title mb-10 flex flex-row items-center px-2 text-center font-new-hero text-4xl font-normal text-white drop-shadow-primary md:text-left md:text-6xl`}
          >
            <span className="mr-3 font-turbinado-pro text-[3.8rem] leading-[49px] lg:mr-5 lg:text-[6.7rem] lg:leading-[65px]">
              Your
            </span>
            <span className="flex-1 overflow-hidden">
              <ReactTextTransition
                springConfig={presets.stiff}
                className={`min-h-[80px] items-center text-center`}
                inline={true}
                style={{}}
              >
                <div className={`whitespace-normal md:whitespace-nowrap`}>
                  {TITLES[index % TITLES.length]}.
                </div>
              </ReactTextTransition>
            </span>
          </h1>
        ) : null}
        {!isHome && title ? (
          <Title title={title} isH1={isH1} titleLength={titleLength} />
        ) : null}
        <p
          className={`mb-10 mt-0 px-5 text-center font-new-hero text-2xl text-white drop-shadow-primary md:px-0 lg:text-left`}
        >
          {content}
        </p>
        {ctas?.length ? (
          <div className={`flex flex-row flex-wrap gap-4`}>
            {ctas.map((cta, index) => {
              return (
                <a
                  key={index}
                  href={cta?.link?.url || "#"}
                  target={cta?.link?.target || ""}
                  className={`bg-white px-10 py-4 font-sans text-lg uppercase text-black transition-all hover:bg-black hover:text-white`}
                >
                  {cta?.link?.title}
                </a>
              );
            })}
          </div>
        ) : null}
        <Image
          src="/down_arrow.svg"
          width={56}
          height={36}
          className={`mt-10 hidden w-14 animate-bounce lg:block`}
          alt="Down Arrow"
        />
      </div>
      {image ? (
        <div className={innerItemClass}>
          <Image
            src={image?.sourceUrl || ""}
            fill={true}
            alt="hero image"
            style={{
              objectFit: "cover",
            }}
          />
        </div>
      ) : null}
      {video ? (
        <div className={`${videoWraperClass}`}>
          <video
            className={`absolute left-0 top-0 h-full w-full object-cover`}
            src={video?.mediaItemUrl || ""}
            autoPlay
            loop
            playsInline
            muted
          />
        </div>
      ) : null}
      <div
        className={`absolute left-0 top-0 h-3/4 w-full bg-gradient-to-b from-[#232323] via-[#040404] to-transparent opacity-50 md:h-screen`}
      ></div>
    </section>
  );
};

export default Hero;

const Title = ({ title, isH1, titleLength }: HeroProps) => {
  const titleLengths: ObjType = {
    full: "max-w-full",
    "2xl": "max-w-2xl",
    "3xl": "max-w-3xl",
    "[56rem]": "max-w-[56rem]",
    "5xl": "max-w-5xl",
    "[71rem]": "max-w-[71rem]",
  };

  return isH1 && title ? (
    <h1
      className={`hero-title mb-10 px-2 text-center font-new-hero text-4xl font-normal text-white drop-shadow-primary md:text-left md:text-6xl`}
    >
      <Content
        content={title}
        className={`${
          titleLength ? titleLengths[titleLength] : "max-w-full"
        } text-center`}
      />
    </h1>
  ) : (
    <h2>{title}</h2>
  );
};

Hero.fragments = {
  entry: gql`
    fragment HeroFragment on Page_Flexiblecontent_Blocks_Hero {
      __typename
      title
      isH1
      variant
      content
      ctas {
        link {
          title
          url
          target
        }
        type
      }
      image {
        ...MediaItemFragment
      }
      video {
        mediaItemUrl
        mimeType
      }
      titleLength
      isHome
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
