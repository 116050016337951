import Link from "next/link";

interface ButtonProps {
  href?: string;
  children: React.ReactNode;
  className?: string;
  type: "primary" | "secondary" | "secondary-outline" | "tertiary" | "alert";
  onClick?: () => void;
  disabled?: boolean;
}

const styleLoader = (type: string) => {
  switch (type) {
    case "primary":
      return `border-b-[3px] border-secondary py-2 px-0 font-bold text-primary font-sans text-xl font-normal uppercase transition duration-300 ease-in-out hover:font-bold`;
    case "secondary":
      return `border-b-[3px] border-white py-2 px-0 font-bold text-white font-sans text-sm md:text-lg font-normal uppercase transition duration-300 ease-in-out hover:font-bold`;
    case "secondary-outline":
      return `bg-white rounded-full border-2 border-primary py-2 px-4 font-bold text-primary font-sans uppercase hover:bg-primary hover:text-white hover:border-primary transition duration-300 ease-in-out`;
    case "tertiary":
      return `bg-white text-primary py-4 px-14 font-sans uppercase hover:text-white hover:bg-secondary transition duration-300 ease-in-out`;
    case "alert":
      return `rounded-full bg-alert py-2 px-4 font-bold text-white font-sans uppercase`;
    default:
      return `rounded-full bg-primary py-2 px-4 font-bold text-white font-sans uppercase`;
  }
};

const Button = ({
  href,
  children,
  className,
  type,
  disabled,
  ...props
}: ButtonProps) => {
  if (href) {
    return (
      <Link
        className={`${styleLoader(type)} ${className}`}
        role="button"
        href={href}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      className={`${styleLoader(type)} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
