import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_IpadSlider } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import Carousel from "./Fragments/Carousel";
import Content from "components/Content/Content";
interface IpadSliderProps extends Page_Flexiblecontent_Blocks_IpadSlider {
  className?: string;
}

const IpadSlider = ({
  className,
  variant,
  title,
  text,
  services,
}: IpadSliderProps) => {
  let wrapperClass = clsx(
    "py-14 px-10 xl:px-4 relative h-auto flex items-center"
  );

  let innerWrapperClass = clsx(
    `items-center relative z-10 container mx-auto flex flex-col relative z-10`
  );

  let innerItemClass = clsx(
    `flex items-start gap-4 w-full`,
    variant === "servicesList"
      ? `justify-center lg:justify-start`
      : `justify-center`
  );

  let ipadClass = "flex-initial w-full lg:max-w-[550px] lgx:max-w-[800px]";

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div className={innerWrapperClass}>
        <div className={innerItemClass}>
          <div className={ipadClass}>
            {title && variant !== "featuredWork" && (
              <h2 className="mb-6 font-new-hero text-5xl font-normal">
                <Content content={title} />
              </h2>
            )}
            {text && (
              <span className="-mt-6 mb-10 inline-block border-b-2 border-secondary pb-2 align-top text-lg md:text-xl">
                {text}
              </span>
            )}
            <Carousel
              title={title || ""}
              variant={variant || "servicesList"}
              services={services || []}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IpadSlider;

export const IPAD_SLIDER_FRAGMENT = gql`
  fragment IpadSliderFragment on Page_Flexiblecontent_Blocks_IpadSlider {
    __typename
    fieldGroupName
    variant
    title
    text
    services {
      cta {
        title
        url
        target
      }
      captionTitle
      captionText
      slideType
      video {
        ...MediaItemFragment
      }
      image {
        ...MediaItemFragment
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
