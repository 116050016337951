import clsx from "clsx";
import { Button } from "components/Button";
import { FeaturedImage } from "components/FeaturedImage";
import { NavigationMenu } from "components/NavigationMenu";
import { useCycle, useScroll } from "framer-motion";
import { AcfLink, MediaItem, MenuItem } from "graphql";
import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import flatListToHierarchical from "utilities/flatListToHierarchical";

export interface HeaderProps {
  menuItems: MenuItem[];
  logo: MediaItem;
  logoWhite: MediaItem;
  cta: AcfLink;
  alwaysShowHeader: boolean;
}

const Header = ({
  menuItems,
  logo,
  logoWhite,
  cta,
  alwaysShowHeader,
}: HeaderProps) => {
  const [open, cycleOpen] = useCycle(false, true);
  const ref = useRef(null);
  const { scrollY } = useScroll({
    target: ref,
    offset: ["start end", "end end"],
  });
  const [isScrolledPast, setIsScrolledPast] = useState(false);

  const heroHeaderClass = clsx(
    isScrolledPast || alwaysShowHeader
      ? `fixed top-0 h-12 lg:h-auto max-h-fit bg-primary py-4 font-sans text-white md:py-0 lg:block lg:px-4 xl:px-0`
      : `absolute top-5`,
    "body-font w-full font-sans text-white lg:px-4 xl:px-0 z-40"
  );

  useEffect(() => {
    return scrollY.onChange((latest) => {
      // If latest is greater than height of header, set isScrolledPast to true
      if (latest > 0) {
        setIsScrolledPast(true);
      } else {
        setIsScrolledPast(false);
      }
    });
  }, [scrollY]);

  return (
    <>
      <header className={heroHeaderClass}>
        <div className="container mx-auto flex h-full flex-row flex-wrap items-center justify-center md:justify-between">
          <div
            className={`flex flex-row flex-wrap items-center justify-center pt-4 md:justify-start`}
          >
            {/* Logo */}
            {logo ? (
              <Link
                href="/"
                className="title-font mb-4 hidden items-center font-medium text-gray-900 lg:block"
              >
                <FeaturedImage
                  image={logoWhite}
                  className={`mb-4 w-48 md:mb-0`}
                />
              </Link>
            ) : (
              <Link
                href="/"
                className="title-font mb-4 flex items-center font-medium text-gray-900 md:mb-0"
              >
                {`j3 Automated Systems`}
              </Link>
            )}
          </div>
          {/* CTA */}
          <div
            className={`flex flex-row flex-wrap items-center justify-center`}
          >
            {/* Navigation Menu */}
            <NavigationMenu
              menuItems={flatListToHierarchical(menuItems)}
              isOpen={open}
              type="secondary"
              toggleOpen={() => cycleOpen()}
            />
            {cta ? (
              <Button type={`primary`} className={`ml-4`} href={cta.url ?? ``}>
                {cta.title}
              </Button>
            ) : null}
          </div>
        </div>
      </header>
      {/* <header
        className={`secondary-header body-font fixed top-0 z-30 hidden max-h-fit w-full bg-primary py-4 font-sans text-white md:py-0 lg:block lg:px-4 xl:px-0`}
      >
        <div className="container mx-auto flex flex-row flex-wrap items-stretch justify-center md:justify-between">
          <div
            className={`flex flex-row flex-wrap items-center justify-center pb-2 pt-4 md:justify-start`}
          >
            {logo ? (
              <Link
                href="/"
                className="title-font mb-4 hidden items-center font-medium text-gray-900 md:mb-0 md:flex lg:block"
              >
                <FeaturedImage
                  image={logoWhite}
                  className={`mb-4 w-48 md:mb-0`}
                />
              </Link>
            ) : (
              <Link
                href="/"
                className="title-font mb-4 flex items-center font-medium text-gray-900 md:mb-0"
              >
                {`j3 Automated Systems`}
              </Link>
            )}
          </div>
          <div
            className={`flex flex-row flex-wrap items-center justify-center`}
          >
            <NavigationMenu
              menuItems={flatListToHierarchical(menuItems)}
              isOpen={open}
              type="secondary"
              toggleOpen={() => cycleOpen()}
            />
            {cta ? (
              <Button type={`primary`} className={`ml-4`} href={cta.url ?? ``}>
                {cta.title}
              </Button>
            ) : null}
          </div>
        </div>
      </header> */}
    </>
  );
};

export default Header;
