import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_Timeline } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import Content from "components/Content/Content";
import Balancer from "react-wrap-balancer";

interface TimelineProps extends Page_Flexiblecontent_Blocks_Timeline {
  className?: string;
}

const Timeline = ({ className, text, title, timelineItems }: TimelineProps) => {
  let wrapperClass = clsx(
    "py-10 relative h-auto flex items-center px-7 md:px-0"
  );

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div className="container relative z-10 mx-auto flex flex-col">
        <div className="flex w-full items-start gap-4">
          <div className="flex-1">
            <h2 className="leading-12 mb-0 text-left font-new-hero text-5xl font-normal">
              <Content content={title} />
            </h2>
            <p className="mb-5 text-left text-lg md:text-xl">
              <Balancer>{text}</Balancer>
            </p>
          </div>
        </div>
        <div className="relative mt-20 flex w-full items-center gap-4">
          {timelineItems &&
            timelineItems.map((item, index) => (
              <div
                key={index}
                className={`relative flex flex-1 flex-col ${
                  index % 2 === 0 ? "mb-[88px]" : "mt-24"
                }`}
              >
                <p
                  className={`text-md whitespace-nowrap text-center md:text-xl ${
                    index % 2 === 0 ? "order-1 mb-6" : "order-2 mt-6"
                  }`}
                >
                  {item?.itemText}
                </p>
                <div
                  className={`relative mx-auto h-[40px] w-[2px] bg-secondary ${
                    index % 2 === 0 ? "top-0 order-2" : "top-full order-1"
                  }`}
                >
                  <div
                    className={`absolute left-[1/2] h-[20px] w-[20px] -translate-x-1/2 transform rounded-full bg-secondary ${
                      index % 2 === 0 ? "top-[-10px]" : "bottom-[-10px]"
                    }`}
                  ></div>
                </div>
              </div>
            ))}
          <div className="absolute left-0 top-1/2 h-[2px] w-full bg-secondary"></div>
        </div>
      </div>
    </section>
  );
};

export default Timeline;

export const TIMELINE_FRAGMENT = gql`
  fragment TimelineFragment on Page_Flexiblecontent_Blocks_Timeline {
    __typename
    text
    title
    timelineItems {
      itemText
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
