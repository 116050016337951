import { gql } from "@apollo/client";
import { MenuItem } from "graphql";
import Link from "next/link";
import { AnimatePresence, m, LazyMotion } from "framer-motion";
import { itemVariants, sideVariants } from "./Fragments/variants";
import { MenuButton } from "./Fragments/MenuButton";
import { useState } from "react";
const loadFeatures = () =>
  import("utilities/framerFeatures.js").then((res) => res.default);

interface NavigationMenuProps {
  menuItems: MenuItem[] | undefined;
  className?: string;
  type?: "primary" | "secondary";
  isMobile?: boolean;
  isOpen?: boolean;
  isFooter?: boolean;
  toggleOpen?: () => void;
}

const NavigationMenu = ({
  menuItems,
  className,
  type = "primary",
  isMobile,
  isOpen,
  isFooter,
  toggleOpen,
}: NavigationMenuProps) => {
  const [subMenuOpen, setSubMenuOpen] = useState({
    id: "",
    isOpen: false,
  });

  if (!menuItems) {
    return null;
  }

  const handleMenu = () => {
    setSubMenuOpen({
      id: "",
      isOpen: false,
    });
    toggleOpen && toggleOpen();
  };

  return (
    <>
      <LazyMotion features={loadFeatures}>
        <AnimatePresence>
          {!isFooter && (
            <MenuButton
              className={`absolute left-4 z-50 lg:hidden`}
              isOpen={isOpen}
              onClick={toggleOpen}
            />
          )}
          {isOpen && (
            <m.aside
              initial={{ width: 0 }}
              animate={{
                width: `100%`,
              }}
              exit={{
                width: 0,
                transition: { delay: 0.7, duration: 0.3 },
              }}
              className={`fixed left-0 top-0 z-50 grid h-screen items-center bg-primary`}
            >
              <a className="absolute left-10 top-6" onClick={toggleOpen}>
                <img src="/icon-close.svg" alt="Close" />
              </a>
              <img
                src="/j3-stamp.svg"
                alt="J3"
                className="absolute -right-20 top-2 z-0"
              />
              <m.nav
                className={`bottom-100 font-heading left-4 right-4 z-40 flex flex-col items-start px-10 text-base md:gap-10 md:px-20 lg:flex-row lg:gap-0`}
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                <div className={`relative h-full w-full`}>
                  <Link
                    href={"/"}
                    className={`block w-full border-b-2 py-7 text-3xl text-white hover:text-white md:pb-10`}
                    onClick={handleMenu}
                  >
                    Home
                  </Link>
                </div>
                {menuItems.map(({ path, label, children }: any) => {
                  const hasChildren = children?.length > 0;
                  const pathIsOpen =
                    subMenuOpen.id === label && subMenuOpen.isOpen;

                  return (
                    <>
                      <m.div
                        key={path}
                        whileHover={{ scale: 1.1 }}
                        variants={itemVariants}
                        onClick={() => {
                          if (hasChildren) {
                            setSubMenuOpen({
                              id: label,
                              isOpen: !subMenuOpen.isOpen,
                            });
                          }
                        }}
                        className="w-full"
                      >
                        {hasChildren ? (
                          <span
                            className={`flex w-full border-b-2 py-7 text-3xl text-white md:pb-10`}
                          >
                            {label}
                            <span className="ml-4">{">"}</span>
                          </span>
                        ) : (
                          <Link
                            href={path ?? "/"}
                            className={`block w-full border-b-2 py-7 text-3xl hover:text-white md:pb-10 ${
                              type === "primary" ? "text-black" : "text-white"
                            }`}
                            onClick={handleMenu}
                          >
                            {label}
                          </Link>
                        )}
                      </m.div>
                      {hasChildren && pathIsOpen ? (
                        <>
                          <m.ul
                            className={`absolute left-0 top-0 flex h-full w-full flex-col items-start gap-0 overflow-y-scroll bg-primary px-20 pt-28 md:gap-10`}
                            // Slide in from left
                            initial={{
                              x: "-100%",
                            }}
                            animate={{
                              x: 0,
                              zIndex: 999999,
                              backgroundColor: "#232323",
                            }}
                            transition={{ type: "tween", duration: 0.2 }}
                          >
                            <Link
                              href={path ?? "/"}
                              onClick={handleMenu}
                              className="absolute left-10 top-6"
                            >
                              <img src="/icon-back.svg" alt="Close" />
                            </Link>
                            {children?.map(({ path, label }: MenuItem) => (
                              <m.li
                                key={`sub-nav-${path}-${label}`}
                                className={`block w-full border-b-2 py-7 text-2xl md:pb-10 md:text-3xl`}
                              >
                                <Link href={path ?? "/"} onClick={handleMenu}>
                                  {label}
                                </Link>
                              </m.li>
                            ))}
                          </m.ul>
                        </>
                      ) : null}
                    </>
                  );
                })}
              </m.nav>
            </m.aside>
          )}
        </AnimatePresence>
      </LazyMotion>
      <nav
        className={`font-heading ${
          !isFooter ? "hidden" : ""
        } h-full flex-col items-center text-base lg:relative lg:flex lg:flex-row`}
      >
        {menuItems.map(({ path, label, children }: any) => {
          const hasChildren = children?.length > 0;
          return (
            <div
              key={`nav-${path}-${label}`}
              className={`relative h-full ${hasChildren ? "group" : ""}`}
            >
              <Link
                key={path}
                href={path ?? "/"}
                className={`flex h-full items-center text-center text-xl hover:text-secondary lg:ml-10 lg:text-left ${
                  type === "primary" ? "text-black" : "text-white"
                }`}
              >
                {label}
              </Link>
              {hasChildren && (
                <div className="top-100 min-w-8 absolute left-0 hidden w-fit transition-all duration-300 ease-in-out group-hover:flex pt-5">
                  <ul className="flex flex-col gap-4 whitespace-nowrap bg-primary px-4 py-6 pt-4 shadow-md">
                    {/* <div className="absolute bottom-full left-1/2 -ml-5 w-fit -translate-x-1/2 transform">
                      <div className=" h-4 w-4 origin-bottom-left rotate-45 transform bg-primary"></div>
                    </div> */}
                    {children?.map(({ path, label }: MenuItem) => (
                      <li
                        key={`nav-${path}-${label}`}
                        className={`transition-all duration-300 ease-in-out`}
                      >
                        <Link
                          href={path ?? "/"}
                          className={`transition-all duration-300 ease-in-out hover:text-secondary`}
                        >
                          {label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          );
        })}
      </nav>
    </>
  );
};

export default NavigationMenu;

NavigationMenu.fragments = {
  entry: gql`
    fragment NavigationMenuItemFragment on MenuItem {
      id
      path
      label
      parentId
      cssClasses
      menu {
        node {
          name
        }
      }
    }
  `,
};
