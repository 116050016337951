import { Button } from "components/Button";
import { FeaturedImage } from "components/FeaturedImage";
import { NavigationMenu } from "components/NavigationMenu";
import { AcfLink, Acf_GoogleMap, MediaItem, MenuItem } from "graphql";
export interface FooterProps {
  logo: MediaItem;
  menuItems: MenuItem[];
  phoneNumber: AcfLink;
  address: Acf_GoogleMap;
  email: AcfLink;
  cta: AcfLink;
}

const Footer = ({ logo, menuItems, cta }: FooterProps) => {
  return (
    <footer className={`font-sans text-primary`}>
      <div
        className={`md:max-w-auto container mx-auto mt-8 flex max-w-[88%] flex-col flex-wrap items-center border-t-2 border-secondary py-16`}
      >
        {/* Logo */}
        <div className={`mb-8 flex flex-row flex-wrap items-center`}>
          {logo ? (
            <FeaturedImage
              image={logo}
              className={`mb-4 w-60 md:mb-0 md:w-80`}
            />
          ) : (
            <a
              href="https://noisytrumpet.com"
              className="title-font mb-4 flex items-center font-medium text-gray-900 md:mb-0"
            >
              {`NT Headless Site Template`}
            </a>
          )}
        </div>
        {/* Contact Info */}
        <div
          className={`mb-10 flex flex-row flex-wrap items-center justify-center md:justify-start`}
        >
          <a
            href={`tel:+12108797066`}
            className={`mx-2 text-black hover:text-secondary`}
          >
            {`210-879-7066`}
          </a>
          <a
            href={`mailto:sales@j3as.com`}
            className={`mx-2 text-black hover:text-secondary`}
          >
            {`sales@j3as.com`}
          </a>
        </div>
        {/* Social Icons */}
        <div
          className={`mb-10 flex flex-row flex-wrap items-center justify-center`}
        >
          <a
            target="_blank"
            href="https://www.instagram.com/j3automatedsystems/"
            className={`mx-2`}
          >
            <img src="/icon-instagram.svg" className={`w-10`} alt="instagram" />
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/J3AutomatedSystems/"
            className={`mx-2`}
          >
            <img src="/icon-facebook.svg" className={`w-10`} alt="facebook" />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/j3-automated-systems"
            className={`mx-2`}
          >
            <img src="/icon-linkedin.svg" className={`w-10`} alt="linkedin" />
          </a>
        </div>
        {/* Menu */}
        <div
          className={`flex flex-row flex-wrap items-center justify-center md:justify-start`}
        >
          <NavigationMenu
            menuItems={menuItems}
            className={`text-white`}
            isFooter={true}
          />
          {cta ? (
            <Button className={`ml-4`} type="secondary" href={cta.url ?? ``}>
              {cta.title}
            </Button>
          ) : null}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
